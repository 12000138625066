import { themeSessionResolver } from "@/lib/theme/sessions.server";
import * as Sentry from "@sentry/remix";
import { withSentry } from "@sentry/remix";
import NProgress from "nprogress";
import { useEffect } from "react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "react-router";
import { useMountedState } from "react-use";
import {
  PreventFlashOnWrongTheme,
  ThemeProvider,
  useTheme,
} from "remix-themes";
import type { Route } from "./+types/root";
import "./app.css";
import { ErrorMessageBoundary } from "./components/error";
import { Toaster } from "./components/ui/sonner";
import { getUserWithSession } from "./lib/auth/isAuth.server";

export const meta: Route.MetaFunction = () => {
  return [
    { title: "Miura Seller" },
    { name: "description", content: "Miura Seller" },
  ];
};

export const links: Route.LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export async function loader({ request }: Route.LoaderArgs) {
  const { getTheme } = await themeSessionResolver(request);
  const user = await getUserWithSession(request, {
    redirectOnError: false,
  }).catch(() => null);

  return {
    theme: getTheme(),
    user: user
      ? {
          id: user.id,
          email: user.email ?? undefined,
          username: user.name ?? undefined,
        }
      : null,
  };
}

const NProgressHook = () => {
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === "loading") {
      NProgress.start();
    } else {
      NProgress.done(true);
    }
  }, [navigation.state]);
  return null;
};

function ThemeColor() {
  const [theme] = useTheme();
  const mounted = useMountedState();
  if (!mounted() || !theme) return null;
  return (
    <meta
      name="theme-color"
      content={theme === "dark" ? "#09090b" : "#ece3d4"}
    />
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider
      specifiedTheme={data?.theme ?? null}
      themeAction="/api/set-theme"
    >
      <LayoutApp>{children}</LayoutApp>
    </ThemeProvider>
  );
}

function LayoutApp({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const [theme] = useTheme();
  useEffect(() => {
    Sentry.setUser(data.user);
  }, [data.user]);
  return (
    <html lang="es" className={theme ?? ""}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ThemeColor />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data?.theme)} />
      </head>
      <body>
        {children}
        <Toaster position="top-center" closeButton />
        <NProgressHook />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.SENTRY_USER = ${JSON.stringify(data.user)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export const shouldRevalidate = () => false;

function App() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  return <ErrorMessageBoundary />;
}
